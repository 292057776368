import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Container,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Input,
  Button,
  VStack,
  HStack,
  Flex,
  Text,
  Spinner,
  Icon,
  Heading,
  Center,
  Textarea,
  Fade,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { BsXCircle, BsCheck2Circle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";

const schema = yup.object().shape({
  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup
    .string()
    .required("Zip code is required")
    .matches(/^\d{5}$/, "Zip code must be exactly 5 digits")
    .transform((value) => (value === "" ? undefined : value)),
  buildingOwnership: yup.string().required("A selection is required"),
  buildingYear: yup
    .number()
    .typeError("Year is required")
    .required("Year is required")
    .min(1800, "Year must be 1800 or later")
    .max(new Date().getFullYear(), "Year cannot be in the future"),
  waterLineReplaced: yup.string().required("A selection is required"),
  waterLineReplacementYear: yup.number().when("waterLineReplaced", {
    is: "yes",
    then: yup
      .number()
      .typeError("Year is required")
      .required("Year is required")
      .min(1800, "Year must be 1800 or later")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
  }),
  waterLineMaterial: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least one option")
    .required("Please select at least one option"),
  materialDeterminationMethod: yup
    .string()
    .required("Please select how you determined the material"),
  hasAlternateContact: yup.string().required("Please select an option"),
  alternateContactDetails: yup.string().when("hasAlternateContact", {
    is: "yes",
    then: yup.string().required("Please provide contact details"),
  }),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};

const WaterLineServiceSurvey = () => {
  const defaultValues = {
    licenseType: "",
    imgUrl: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [succeeded, setSucceeded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const watchWaterLineReplaced = watch("waterLineReplaced");
  const watchHasAlternateContact = watch("hasAlternateContact");

    const FormSuccess = () => (
    <Box m="20">
      <Box textAlign="center" my="4">
        <Center>
          <BsCheck2Circle size="4rem" />
        </Center>
      </Box>
      <Heading as="h2" textAlign="center" my="4">
        Survey Submitted Successfully!
      </Heading>
      <Text textAlign="center" my="4">
        Thank you for submitting your survey.
      </Text>
    </Box>
  );

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const sendFormData = async (data) => {
    setIsSubmitting(true);

     fetch('/.netlify/functions/send-water-line-service-survey', {
             method: 'POST',
             body: JSON.stringify(data),
         })
    .then((res) => {
      //console.log(res);
      if (res.status === 200) {
        setIsSubmitting(false);
        setSucceeded(true);
        reset();
        scrollTop();
      } else {
        setSucceeded(false);
        setIsSubmitting(false);
        console.log("network error");
        alert("Network error. Please try again later.");
      }
    });
  };

  return (
    <Container maxW="container.xl">
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <Box my="8">
            <form onSubmit={handleSubmit(sendFormData)}>
              <Box my="8">
                <Text fontWeight="bold" fontSize="1.5rem">
                  Location Information
                </Text>

                <FormControl isInvalid={errors.address} mb="8">
                  <FormLabel fontWeight="700">Address</FormLabel>
                  <Input
                    type="text"
                    {...register("address")}
                    autoComplete="on"
                    borderColor={errors.address ? "red.500" : "inherit"}
                    _hover={{
                      borderColor: errors.address ? "red.300" : "gray.300",
                    }}
                    _focus={{
                      borderColor: errors.address ? "red.500" : "blue.500",
                    }}
                  />
                  {errors.address && (
                    <FormErrorMessage>
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.address?.message}</Box>
                      </HStack>
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Flex w="100%" flexWrap="wrap" mb="8">
                  <Box
                    w={{ base: "100%", md: "33.33%" }}
                    pr={{ base: "0", md: "4" }}
                  >
                    <FormControl isInvalid={errors.city}>
                      <FormLabel fontWeight="700">City</FormLabel>
                      <Input
                        type="text"
                        {...register("city")}
                        autoComplete="on"
                      />
                      {errors.city && (
                        <FormErrorMessage>
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.city?.message}</Box>
                          </HStack>
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    w={{ base: "100%", md: "33.33%" }}
                    pr={{ base: "0", md: "4" }}
                  >
                    <FormControl isInvalid={errors.state}>
                      <FormLabel fontWeight="700">State</FormLabel>
                      <Input
                        type="text"
                        {...register("state")}
                        autoComplete="on"
                      />
                      {errors.state && (
                        <FormErrorMessage>
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.state?.message}</Box>
                          </HStack>
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>

                  <Box w={{ base: "100%", md: "33.33%" }}>
                    <FormControl isInvalid={errors.zip}>
                      <FormLabel fontWeight="700">Zip Code</FormLabel>
                      <Input
                        type="number"
                        {...register("zip")}
                        autoComplete="on"
                      />
                      {errors.zip && (
                        <FormErrorMessage>
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.zip?.message}</Box>
                          </HStack>
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </Flex>

                <Text fontWeight="bold" fontSize="1.5rem">
                  Please answer the following questions
                </Text>
                <Box mb="8">
                  <FormControl
                    as="fieldset"
                    mb="8"
                    isInvalid={!!errors.buildingOwnership}
                  >
                    <FormLabel as="legend" fontWeight="700">
                      Do you own or rent this building?
                    </FormLabel>
                    <Controller
                      name="buildingOwnership"
                      control={control}
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row" spacing={4}>
                            <Radio value="own">Own</Radio>
                            <Radio value="rent">Rent</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.buildingOwnership?.message}</Box>
                      </HStack>
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb="8"
                    isInvalid={!!errors.buildingYear}
                    w={{ base: "100%", md: "33.33%" }}
                  >
                    <FormLabel fontWeight="700">
                      What year was the building built?
                    </FormLabel>
                    <Input
                      type="number"
                      {...register("buildingYear")}
                      placeholder="YYYY"
                    />
                    <FormErrorMessage>
                      <HStack spacing="5px">
                        <VscWarning />
                        <Box>{errors.buildingYear?.message}</Box>
                      </HStack>
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    as="fieldset"
                    mb="8"
                    isInvalid={!!errors.waterLineReplaced}
                  >
                    <FormLabel as="legend" fontWeight="700">
                      To your knowledge, has the water service line for your
                      building ever been replaced?
                    </FormLabel>
                    <Controller
                      name="waterLineReplaced"
                      control={control}
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="column">
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                            <Radio value="notSure">Not sure</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      <HStack spacing="5px">
                        <VscWarning />
                        <Box>{errors.waterLineReplaced?.message}</Box>
                      </HStack>
                    </FormErrorMessage>
                  </FormControl>

                  {watchWaterLineReplaced === "yes" && (
                    <Fade
                      in={true}
                      transition={{
                        enter: { duration: 0.5 },
                        exit: { duration: 0.5 },
                      }}
                    >
                      <FormControl
                        mb="8"
                        isInvalid={!!errors.waterLineReplacementYear}
                        w={{ base: "100%", md: "33.33%" }}
                      >
                        <FormLabel fontWeight="700">
                          If so, what year was it replaced?
                        </FormLabel>
                        <Input
                          type="number"
                          {...register("waterLineReplacementYear")}
                          placeholder="YYYY"
                        />
                        <FormErrorMessage>
                          <HStack spacing="5px">
                            <VscWarning />
                            <Box>
                              {errors.waterLineReplacementYear?.message}
                            </Box>
                          </HStack>
                        </FormErrorMessage>
                      </FormControl>
                    </Fade>
                  )}

                  <FormControl mb="8" isInvalid={!!errors.waterLineMaterial}>
                    <FormLabel fontWeight="700">
                      What material is your water service line? You may choose
                      more than one.
                    </FormLabel>
                    <Controller
                      name="waterLineMaterial"
                      control={control}
                      rules={{ required: "Please select at least one option" }}
                      render={({ field: { onChange, value } }) => (
                        <CheckboxGroup onChange={onChange} value={value || []}>
                          <VStack align="start" spacing={2}>
                            <Checkbox value="Lead">Lead</Checkbox>
                            <Checkbox value="Plastic">
                              Plastic (HDPE, PVC)
                            </Checkbox>
                            <Checkbox value="Copper">Copper</Checkbox>
                            <Checkbox value="Steel">
                              Steel (ductile iron pipe, galvanized)
                            </Checkbox>
                            <Checkbox value="Unknown">Unknown</Checkbox>
                            <Checkbox value="Cannot Locate">
                              Cannot locate service line
                            </Checkbox>
                          </VStack>
                        </CheckboxGroup>
                      )}
                    />
                    {errors.waterLineMaterial && (
                      <FormErrorMessage>
                        <HStack spacing="5px">
                          <VscWarning />
                          <Box>{errors.waterLineMaterial.message}</Box>
                        </HStack>
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    mb="8"
                    isInvalid={!!errors.materialDeterminationMethod}
                  >
                    <FormLabel fontWeight="700">
                      How did you determine the material? Choose one.
                    </FormLabel>
                    <Controller
                      name="materialDeterminationMethod"
                      control={control}
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="column" spacing={2}>
                            <Radio value="Scratch Test">Scratch test</Radio>
                            <Radio value="Visual">Visual</Radio>
                            <Radio value="Professional">
                              Plumber or other qualified professional
                            </Radio>
                            <Radio value="CannotLocate">
                              Cannot locate service line
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      <HStack spacing="5px">
                        <VscWarning />
                        <Box>{errors.materialDeterminationMethod?.message}</Box>
                      </HStack>
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mb="8" isInvalid={!!errors.hasAlternateContact}>
                <FormLabel fontWeight="700">
                  Is there someone else we can contact who might know this information about your residence?
                </FormLabel>
                <Controller
                  name="hasAlternateContact"
                  control={control}
                  rules={{ required: "Please select an option" }}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Stack direction="row" spacing={4}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>
                  <HStack spacing="5px">
                    <VscWarning />
                    <Box>{errors.hasAlternateContact?.message}</Box>
                  </HStack>
                </FormErrorMessage>
              </FormControl>

              {watchHasAlternateContact === "yes" && (
                <Fade
                  in={true}
                  transition={{
                    enter: { duration: 0.5 },
                    exit: { duration: 0.5 },
                  }}
                >
                  <FormControl mb="8" isInvalid={!!errors.alternateContactDetails}>
                    <FormLabel fontWeight="700">Contact details</FormLabel>
                    <Textarea
                      {...register("alternateContactDetails")}
                      placeholder="Please provide contact details"
                    />
                    <FormErrorMessage>
                      <HStack spacing="5px">
                        <VscWarning />
                        <Box>{errors.alternateContactDetails?.message}</Box>
                      </HStack>
                    </FormErrorMessage>
                  </FormControl>
                </Fade>
              )}

                </Box>
              </Box>

              <Box my="8">
                <Button
                  type="submit"
                  textAlign="left"
                  isDisabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : succeeded ? (
                    "Submitted!"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default WaterLineServiceSurvey;
